import React from "react"
import { graphql } from "gatsby"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"
import { ButtonTrace } from "../../components/Button"

const UL = Styled.ul`
  li {
	font-size: 2rem;
	cursor: pointer;
	&:hover {
		opacity: 0.6;
		font-weight: bold;
	}
  }
`
const Education = ({ data }) => {
  const header = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={header}
      alt={"Education Header"}
      height={600}
      heightMobile={300}
    >
      <SEO
        title="Education"
        keywords={["Education", "Beyond The Apprenticeship", "Matt Pehrson"]}
      />

      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>Education</RuledBreak>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <p>
            At Beyond the Apprenticeship, we truly believe that any tattoo
            artist or business owner, regardless of experience will find value
            in what we provide.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <p>
            In the program, you will receive access to two instructional videos,
            and one webinar each month. This will include access to a closed
            facebook group where you can network with other artists and grow as
            a collective.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <ButtonTrace to="/education/membership" btn={3}>
            Learn More
          </ButtonTrace>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <p>
            The instructional videos can also be purchased individually, and a
            membership will provide you a discount code for any archived videos.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <h2 className="bold-underline">Instructional videos will include:</h2>
        </div>
        <div className="col-md-5 mr-auto ml-auto py-3">
          <UL>
            <li>How to start treating yourself as a business</li>
            <li>Photoshop for tattooing</li>
            <li>
              Using digital tools to improve sales and efficiency in designing
            </li>
            <li>Social media for tattooing </li>
            <li>Digital photography</li>
          </UL>
        </div>
        <div className="col-md-5 mr-auto ml-auto py-3">
          <UL>
            <li>Marketing yourself</li>
            <li>Budgeting and Supplies</li>
            <li>Communicating with owners or contractors</li>
            <li>
              Using documentation methods to improve organization and capture
              customer data
            </li>
            <li>Minimums and deposits</li>
            <li>And many more topics</li>
          </UL>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>Ask Yourself This</RuledBreak>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <h3>
            Have you ever met a successful person, who would choose not to
            invest in themselves?
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <ButtonTrace to="/education/membership" btn={3}>
            Learn More
          </ButtonTrace>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query EducationQuery {
    header: allFile(filter: { name: { eq: "headerEducation_1300x680" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Education
